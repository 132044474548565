/* Importing the fonts */
@import url('https://fonts.googleapis.com/css2?family=Bellota+Text:wght@400;700&family=Fraunces:wght@400;600;700&display=swap');

/* Global resets and basic styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Ensures consistent padding and border behavior */
}

body {
  margin: 0;
  font-family: 'Bellota Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #f8fafc; /* Matches theme background color */
  color: #333; /* Darker grey text for better readability */
  font-size: 16px; /* Standard base font size */
  line-height: 1.6; /* Improved line height for better readability */
  -webkit-font-smoothing: antialiased; /* Smooth font rendering */
  -moz-osx-font-smoothing: grayscale; /* Smooth font rendering for Mac */
}

/* Styling for code blocks */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: #f4f4f4; /* Light background for code blocks */
  padding: 2px 4px; /* Padding for better spacing */
  border-radius: 4px; /* Rounded corners */
  color: #d32f2f; /* Slightly dark red for code text */
}

/* Links */
a {
  color: #00796b; /* Primary teal color */
  text-decoration: none; /* Remove underline by default */
  transition: color 0.3s ease; /* Smooth transition for hover effect */
}

a:hover {
  color: #004d40; /* Darker teal on hover */
  text-decoration: underline; /* Underline on hover for clarity */
}

/* Buttons */
button {
  font-family: 'Bellota Text', sans-serif; /* Use Bellota Text for buttons */
  font-size: 1rem;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #004d40; /* Darker teal for hover effect */
  color: #ffffff;
}

/* Input and Textarea fields */
input,
textarea {
  font-family: inherit;
  font-size: 1rem;
  border: 1px solid #ddd; /* Light border for inputs */
  border-radius: 4px;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
}

input:focus,
textarea:focus {
  border-color: #00796b; /* Primary teal color for focused state */
  outline: none; /* Remove default outline */
}

/* General container for consistent padding */
.container {
  padding: 16px;
  max-width: 1200px;
  margin: 0 auto; /* Center align the container */
}
